import { useState, useEffect, useCallback } from 'react';
import { KirbySection, KirbySpinner, KirbyTableClient } from "maples-kirby-react";
import { maplesStandardXLSX } from "maples-kirby";
import { getTableHeaders } from "./serviceRequestsGridColumns";
import styles from "./serviceRequests.module.scss";
import { distinct, selectMany } from 'helpers/array';
import utils from 'components/Pages/ServiceRequests/OrderHistory/utils';
import { FetchStatus } from 'common-types/fetchStatusEnum';
import { toOrderForTable } from 'components/Pages/ServiceRequests/OrderHistory/types/OrderForTable';
import { FetchOrderSummariesAction } from 'modules/order/types/order-actions-types';
import { OrderSummaryDto } from 'common-types/serviceRequests/orderSummaryDto';
import { GridFilterOption } from 'common-types/grids/gridFilterOption';
import { addToast, updateToast } from "components/common/Toaster";
import * as XLSX from 'sheetjs-style';
import { uploadFileToBlob } from 'api/audit-api';
import { Actions, hasAction } from '../../helpers/userActions';
import AccessDenied from '../Pages/Errors/accessDenied';
import useFeatureFlag from 'helpers/featureManagement/useFeatureFlag';
import { ActionTypeEnum } from '../../common-types/actionTypeEnum';
import { pageSizeoptions, defaultPageSize } from "constants/pagination";

window.XLSX = XLSX;
declare global {
  interface Window {
    XLSX: any;
  }
}

class ServiceRequestsProps {
  orderSummaries: OrderSummaryDto[];
  fetchStatus: FetchStatus | undefined;
  fetchOrderSummaries: () => FetchOrderSummariesAction;
}

export default function ServiceRequests(props: ServiceRequestsProps) {
  const { fetchOrderSummaries, orderSummaries, fetchStatus } = props;
  const [statusOptions, setStatusOptions] = useState<GridFilterOption[]>([]);
  const [docTypes, setDocTypes] = useState<GridFilterOption[]>([]);
  const [tableMessage, setTableMessage] = useState<string>('');
  const [userOptions, setUserOptions] = useState<GridFilterOption[]>([]);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [serviceReqestsEnabled, serviceRequestsFlagLoaded] = useFeatureFlag('ServiceRequests');

  const excelExportFileName = 'Service Request Overview.xlsx';

  /*
    Fetch orders for all logged in users entities.
  */
  useEffect(() => {
    document.title = "Service Request Overview";
    fetchOrderSummaries();
  }, []);

  /*
    Sets the table header filter values when the order summaries change.
  */
  useEffect(() => {
    if (orderSummaries) {
      const distinctStatusIds = distinct(orderSummaries.map((a: OrderSummaryDto) => a.serviceRequestOrderStatusId))
        .sort((ida: any, idb: any) => ida - idb);
      setStatusOptions(distinctStatusIds.map((statusId: any) => utils.toOption(statusId.toString(), utils.getStatusLabel(statusId))));

      const distinctDocTypesOrdered = distinct(
        selectMany(orderSummaries, a => a.orderItems).map(a => utils.docTypeDisplayName(a))
          .filter(a => a !== null && a !== undefined)
          .sort((a, b) => (a ?? '').localeCompare(b ?? ''))
      ).map((a: any) => utils.toOption(a, a)) ?? [];
      setDocTypes(distinctDocTypesOrdered);

      const users = distinct(orderSummaries.map((a: any) => a.orderedByName as string))
        .filter(a => a !== null && a !== undefined)
        .sort((a, b) => (a ?? '').localeCompare(b ?? ''));
      setUserOptions(users.map(user => utils.toOption(user)));
    }
  }, [orderSummaries]);

  useEffect(() => {
    docTypes.length > 0 && statusOptions.length > 0 && userOptions.length > 0 && setFiltersLoaded(true);
  }, [docTypes, statusOptions, userOptions]);

  /*
    Change the table no data message based on changes to teh fetch status of the API call.
  */
  useEffect(() => {
    if (fetchStatus === FetchStatus.Error)
      setTableMessage('An error occured when loading service requests.');
    else
      setTableMessage('No service requests match your selection criteria');
  }, [fetchStatus]);

  const getServiceRequestsGridColumns = useCallback(() => {
    return getTableHeaders(docTypes, statusOptions, userOptions);
  }, [docTypes, statusOptions, userOptions]);

  return (
    <>
      {hasAction(Actions.ViewEntity) && serviceReqestsEnabled ? (
        <div className={"portalForm"} data-testid={'service-requests-overview'} >
          <div className={styles.srContainer}>
            <KirbySection sectionTitle="Service Request Overview">
              <div className={styles.sectionContent}>
                {(fetchStatus === FetchStatus.InProgress && orderSummaries.length === 0) || !filtersLoaded ?
                  <div className={styles.loadingSpinner}>
                    <KirbySpinner />Fetching Service Requests...
                  </div>
                  :
                  <KirbyTableClient
                    columns={getServiceRequestsGridColumns()}
                    data={orderSummaries.map(toOrderForTable)}
                    defaultPageSize={defaultPageSize}
                    pageSizeOptions={pageSizeoptions}
                    emptyMessage={tableMessage}
                    tablePart="serviceRequestsTable"
                    widthBreakpoints={{
                      responsiveWidth: 60,
                    }}
                    sessionStoreName="serviceRequestsOverview"
                    allowXlsxExport={{
                      fileName: excelExportFileName,
                      placement: 'toolbar',
                      icon: true,
                      interceptDownload: async (fileContents: any, mimeType: any) => {
                        const toastID = await addToast("Exporting Service Requests", "", "spinner");
                        const excelBlob = new Blob([fileContents], { type: mimeType })
                        const excelFile = new File([excelBlob], excelExportFileName, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        await uploadFileToBlob(ActionTypeEnum.ExportServiceRequestOverview, "Exporting Service Request Overview Excel", excelFile);
                        updateToast(toastID, "Service Requests Exported", '', 'success');
                        return true;
                      },
                      options: {
                        documentProcessing: maplesStandardXLSX
                      }
                    }
                    }
                    showManageColumns
                    tableStore={{
                      name: "PhoenixServiceRequestsOverview",
                      version: 1
                    }}
                  />
                }
              </div>
            </KirbySection>
          </div>
        </div>
      ) : serviceRequestsFlagLoaded && (<AccessDenied></AccessDenied>)
      }
    </>);
}
