import { EntityDto } from "common-types";
import { FetchStatus } from "common-types/fetchStatusEnum";
import { HomePageType } from "common-types/homePageTypeEnum";
import { PagedListDto } from "common-types/paging/pagedListDto";
import { entityGridMessages } from "constants/copy/entities/entitiesGridMessages";
import { kirbySearch } from "maples-kirby-icons";
import {
  KirbySection,
  KirbyTableServer
} from "maples-kirby-react";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import styles from "../Entities.module.scss";
import { entityGridColumns } from "../entityGridColumns";
import useFeatureFlag from "helpers/featureManagement/useFeatureFlag";
import { pageSizeoptions } from "constants/pagination";

export interface AllEntitiesProps {
  fetchAllEntities: (
    pageNumber: number,
    pageSize: number,
  ) => void;
  allEntities: PagedListDto<EntityDto> | undefined;
  fetchStatus: FetchStatus | undefined;
  currentPage: number;
  pageSize: number;
  setCurrentPage: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  setHomePageType: (homePageType: HomePageType) => void;
}

export default function AllEntities({
  fetchAllEntities,
  allEntities,
  fetchStatus,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  setHomePageType,
}: AllEntitiesProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [serviceRequestsEnabled, featureFlagsLoaded] = useFeatureFlag('ServiceRequests');
  const columns: Array<any> = entityGridColumns(serviceRequestsEnabled);
  const { totalCount } = allEntities ?? {};
  const [routeLoading, setRouteLoading] = useState(false);

  useEffect(() => {
    fetchAllEntities(currentPage, pageSize);
  }, [fetchAllEntities, currentPage, pageSize]);

  useEffect(() => {
    if (location.state?.reset) {
      navigate(".", { replace: true });
      setCurrentPage(1);
      setRouteLoading(true);
      setTimeout(() => {
        setRouteLoading(false);
      }, 100)
    }
  }, [location]);

  useEffect(() => {
    setRouteLoading(false);
  }, [routeLoading]);

  const handleDataChange = useCallback(
    ({
      newPageIndex,
      newPageSize,
    }: {
      newPageIndex: number;
      newPageSize: number;
    }) => {
      setCurrentPage(newPageIndex);
      setPageSize(newPageSize);
    },
    [setCurrentPage, setPageSize]
  );

  return (
    <>    
      {!routeLoading === true &&
        <KirbySection sectionTitle={entityGridMessages.Title}>
          <div className={styles.content}>
            {fetchStatus === FetchStatus.NotFound && (
              <div>{entityGridMessages.NotFound}</div>
            )}
            {(fetchStatus !== FetchStatus.NotFound && featureFlagsLoaded) && (
              <KirbyTableServer
                data-testid="entitylisttable"
                columns={columns}
                data={allEntities?.page}
                pageSizeOptions={pageSizeoptions}
                defaultPageSize={pageSize}
                rowCount={totalCount}
                hideReset={true}
                widthBreakpoints={{
                  responsiveWidth: 50,
                  stickyColumnsActive: 25,
                }}
                handleDataChange={handleDataChange}
                headerSortable={false}
                loading={{
                  active: fetchStatus === FetchStatus.InProgress,
                  text: entityGridMessages.FetchInProgress,
                }}
                tablePart="clientTable"
                pageIndex={currentPage}
                emptyMessage={entityGridMessages.NotFound}
                showManageColumns
                tableStore={{
                  name: "PhoenixEntityListTable",
                  version: 1
                }}
                customActions={[{
                  label: entityGridMessages.ToggleRecentlyViewedEntities,
                  func: () => setHomePageType(HomePageType.RecentlyViewedEntities),
                  placement: "toolbar",
                  icon: {
                    icon: kirbySearch
                  }
                }]}
              />
            )}
          </div>
        </KirbySection>
      }
    </>
  );
}
