import { EntityDto, PagedListDto } from "../../../../common-types";
import { FetchStatus } from "../../../../common-types/fetchStatusEnum";
import { searchResultsMessages } from "../../../../constants/copy/entities/searchResultsMessages";
import {
  KirbyLoaderPage,
  KirbySection,
  KirbyTableServer,
} from "maples-kirby-react";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import styles from "../Entities.module.scss";
import { entityGridColumns } from "../entityGridColumns";
import useFeatureFlag from "../../../../helpers/featureManagement/useFeatureFlag";
import { pageSizeoptions } from "constants/pagination";
export interface SearchResultsProps {
  fetchSearchResults: (
    pageNumber: number,
    pageSize: number,
    searchTerm: string
  ) => void;
  searchResults: PagedListDto<EntityDto> | undefined;
  searchTerm: string;
  redirectedForSingleResult: boolean;
  fetchStatus: FetchStatus | undefined;
  currentPage: number;
  pageSize: number;
  setCurrentPage: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  clearEntitiesSearchTerm: () => void;
  recordRedirect: () => void;
}

export default function SearchResults({
  fetchSearchResults,
  searchResults,
  searchTerm,
  redirectedForSingleResult,
  fetchStatus,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  clearEntitiesSearchTerm,
  recordRedirect,
}: SearchResultsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [serviceRequestsEnabled, featureFlagsLoaded] =
    useFeatureFlag("ServiceRequests");
  const columns: Array<any> = entityGridColumns(serviceRequestsEnabled);
  const [routeLoading, setRouteLoading] = useState(false);

  useEffect(() => {
    fetchSearchResults(currentPage, pageSize, searchTerm);
  }, [fetchSearchResults, currentPage, pageSize, searchTerm]);

  useEffect(() => {
    if (location.state?.reset) {
      navigate(".", { replace: true });
      setCurrentPage(1);
      setPageSize(20);
      clearEntitiesSearchTerm();
      setRouteLoading(true);
      setTimeout(() => {
        setRouteLoading(false);
      }, 100);
    }
  }, [location]);

  useEffect(() => {
    if (
      !redirectedForSingleResult &&
      searchResults &&
      searchResults.page.length === 1 &&
      searchResults.currentPage == 1
    ) {
      navigateAndCleanSearchState(searchResults.page[0].id);
    }
  }, [navigate, searchResults, recordRedirect, redirectedForSingleResult]);

  useEffect(() => {
    setRouteLoading(false);
  }, [routeLoading]);

  const handleDataChange = useCallback(
    ({
      newPageIndex,
      newPageSize,
    }: {
      newPageIndex: number;
      newPageSize: number;
    }) => {
      setCurrentPage(newPageIndex);
      setPageSize(newPageSize);
    },
    [setCurrentPage, setPageSize]
  );

  const navigateAndCleanSearchState = (entityId: number) => {
    clearEntitiesSearchTerm();
    navigate(`entities/${entityId}`);
    recordRedirect();
  };

  return (
    <>
      {fetchStatus === FetchStatus.InProgress && (
        <KirbyLoaderPage
          opaque={false}
          description={`${searchResultsMessages.SearchInProgress} '${searchTerm}'`}
        />
      )}
      {!routeLoading === true && (
        <KirbySection
          sectionTitle={
            searchResultsMessages.Title + " for '" + searchTerm + "'"
          }>
          <div className={styles.content}>
            {fetchStatus === FetchStatus.NotFound && (
              <div>{searchResultsMessages.NotFound}</div>
            )}
            {fetchStatus !== FetchStatus.NotFound && featureFlagsLoaded && (
              <KirbyTableServer
                data-testid="entitylisttable"
                columns={columns}
                data={searchResults?.page}
                pageSizeOptions={pageSizeoptions}
                defaultPageSize={pageSize}
                rowCount={searchResults?.totalCount}
                hideReset={true}
                widthBreakpoints={{
                  responsiveWidth: 50,
                  stickyColumnsActive: 25,
                }}
                handleDataChange={handleDataChange}
                headerSortable={false}
                loading={{
                  active: false,
                  text: searchResultsMessages.SearchInProgress,
                }}
                tablePart="clientTable"
                pageIndex={currentPage}
                emptyMessage={searchResultsMessages.NoSearchMatch}
              />
            )}
          </div>
        </KirbySection>
      )}
    </>
  );
}
